import { isVpnMode, playListUrl } from "../../state";
//@ts-ignore
import { createEffect, onCleanup } from "solid-js";

let API_BASE = "";

createEffect(() => {
  API_BASE = isVpnMode() ? playListUrl() : playListUrl();
});

// Watch for changes in playListUrl and update API_BASE accordingly
createEffect(() => {
  const data = playListUrl();
  API_BASE = data;
});

const basePosterSize = "w185";


export function getImageUrl(path: string, posterSize: string = basePosterSize) {
  return posterSize + path;
}

export function getImageUrlAbsolute(path: string = basePosterSize) {
  return path;
}

function _get(path: string, params: RequestInit = {}) {
  const fullUrl = API_BASE + path;
  const encodedUrl = btoa(fullUrl); // Encode fullUrl to base64

  // Construct final URL based on vpnMode
  const finalUrl = isVpnMode()
    ? `https://proxy1.orbixplay.com/${encodedUrl}`
    : fullUrl;


  return fetch(finalUrl, {
    ...params,
  }).then((r) => r.json());
}


export default {
  _get,
};

import { createEffect, on } from "solid-js";
import {
  globalStream,
  globalStreamType,
  playListPassword,
  playListUrl,
  playListUsername,
} from "../state";

let manifestUri = "";
let avPlayer;

function initializeAVPlayer() {
  avPlayer = webapis.avplay;

  const listener = {
    // onbufferingstart: function () {
    //   console.log("Buffering start.");
    //   hideSeekBar(); // Hide seek bar during buffering
    // },
    // onbufferingprogress: function (percent) {
    //   console.log("Buffering progress data: " + percent);
    // },
    // onbufferingcomplete: function () {
    //   console.log("Buffering complete.");
    //   avPlayer.play();
    //   if (globalStreamType() !== "live") {
    //     showSeekBar(); // Only show seek bar when it's not a live stream
    //   }
    // },
    // oncurrentplaytime: function (currentTime) {
    //   console.log("Current playtime: " + currentTime);
    //   if (globalStreamType() !== "live") {
    //     updateSeekBar(); // Update seek bar only for non-live streams
    //   }
    // },
    // onevent: function (eventType, eventData) {
    //   console.log("Event type: " + eventType + ", data: " + eventData);
    // },
    // onerror: function (eventType) {
    //   console.log("Error type: " + eventType);
    // },
    // onsubtitlechange: function (duration, text) {
    //   console.log("Subtitle Text: " + text);
    // },
    // ondrmevent: function (drmEvent, drmData) {
    //   console.log("DRM callback: " + drmEvent + ", data: " + drmData);
    // },
  };

  avPlayer.setListener(listener);
  avPlayer.stop();
}


document.addEventListener("DOMContentLoaded", function () {
  // initializeAVPlayer();
  // addKeyListeners(); // Ensure this is called only once
  // setupSeekBarAutoHide();
  // setupVideoListeners();

  // createEffect(
  //   on(
  //     [globalStream, globalStreamType],
  //     ([str, type]) => {
  //       if (type === "live") {
  //         console.log("Live stream selected:", str);
  //         manifestUri = `${playListUrl()}/live/${playListUsername()}/${playListPassword()}/${str}.ts`;
  //         hideSeekBar(); // Hide seek bar for live streams
  //       } else {
  //         console.log("Non-live stream selected");
  //         manifestUri = `${playListUrl()}/${type}/${playListUsername()}/${playListPassword()}/${str}.mp4`;
  //         showSeekBar(); // Show seek bar for non-live streams
  //       }

  //       resetSeekBar(); // Reset the seek bar for every new stream

  //       avPlayer.stop();
  //       avPlayer.open(manifestUri);
  //       avPlayer.setDisplayRect(0, 0, 1920, 1080);
  //       avPlayer.prepareAsync(
  //         () => {
  //           console.log("Preparation complete for:", manifestUri);
  //           avPlayer.play();
  //           updateTotalDuration(); // Call this here to update the total duration after preparation
  //         },
  //         (error) => {
  //           console.log("Preparation error:", error);
  //         }
  //       );
  //     },
  //     { defer: true }
  //   )
  // );


  // video.addEventListener("timeupdate", updateSeekBar);
  // video.addEventListener("loadedmetadata", updateTotalDuration);
});

function resetSeekBar() {
  const playedBar = document.getElementById("played-bar");
  const thumb = document.getElementById("thumb");
  playedBar.style.width = "0%";
  thumb.style.left = "0%";
  updateCurrentTime(0);
}

function updateSeekBar() {
  // const playedBar = document.getElementById("played-bar");
  // const remainingBar = document.getElementById("remaining-bar");
  // const thumb = document.getElementById("thumb");

  // if (avPlayer.getDuration() > 0) {
  //   const percentPlayed =
  //     (avPlayer.getCurrentTime() / avPlayer.getDuration()) * 100;
  //   playedBar.style.width = `${percentPlayed}%`;
  //   remainingBar.style.width = `${100 - percentPlayed}%`; // Adjust remaining width
  //   thumb.style.left = `${percentPlayed}%`;
  //   updateCurrentTime(avPlayer.getCurrentTime());
  // }
}

function updateCurrentTime(currentTime) {
  // const currentTimeDisplay = document.getElementById("current-time");
  // const minutes = Math.floor(currentTime / 60000);
  // const seconds = Math.floor((currentTime % 60000) / 1000);
  // currentTimeDisplay.textContent = `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
}

function updateTotalDuration() {
  const totalDurationDisplay = document.getElementById("total-duration");

  // Check if the video is prepared or playing
  if (avPlayer.getDuration() > 0 && avPlayer.getState() === "PLAYING") {
    const minutes = Math.floor(avPlayer.getDuration() / 60000);
    const seconds = Math.floor((avPlayer.getDuration() % 60000) / 1000);
    totalDurationDisplay.textContent = `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
  } else {
    totalDurationDisplay.textContent = "00:00"; // Reset to 0 if video is not playing
  }
}

function setupVideoListeners() {
  const video = document.getElementById("video");

  video.addEventListener("play", () => {
    if (globalStreamType() !== "live") {
      showSeekBar(); // Show seek bar when video starts playing, but not for live streams
    }
  });

  video.addEventListener("pause", () => {
    hideSeekBar(); // Hide seek bar when video is paused
  });

  video.addEventListener("waiting", () => {
    hideSeekBar(); // Hide seek bar when video is buffering
  });

  video.addEventListener("playing", () => {
    if (globalStreamType() !== "live") {
      showSeekBar(); // Show seek bar when video is playing, but not for live streams
    }
  });
}



function hideSeekBar() {
  // const seekBar = document.getElementById("custom-seek-bar");
  // if (seekBar) {
  //   seekBar.classList.add("hidden");
  // }
}

function showSeekBar() {
  // const seekBar = document.getElementById("custom-seek-bar");
  // // Only show the seek bar if it's not a live stream
  // if (globalStreamType() !== "live" && seekBar) {
  //   seekBar.classList.remove("hidden");
  //   resetSeekBarAutoHide(); // Reset auto-hide timeout when showing the seek bar
  // }
}


let isSeeking = false; // Track if seeking is in progress

function addKeyListeners() {
  document.addEventListener("keydown", (event) => {
    if (globalStreamType() === "live") {
      return; // Exit early if live stream
    }

    if (avPlayer) {
      const seekStep = 15000; // 5 seconds in milliseconds

      if (event.key === "ArrowRight" || event.key === "ArrowLeft") {
        avPlayer.pause(); // Pause the video during seeking
        isSeeking = true; // Set seeking state

        const direction = event.key === "ArrowRight" ? seekStep : -seekStep;
        avPlayer.jumpForward(direction); // Jump forward or backward
        updateSeekBar();
        showSeekBar(); // Show seek bar during seeking

        // After a short delay, start buffering
        setTimeout(() => {
          if (isSeeking) {
            avPlayer.buffer(); // Trigger buffering
            avPlayer.play(); // Start playing after buffering
            isSeeking = false; // Reset seeking state
          }
        }, 500); // Adjust this delay as needed
      }
    }
  });
}

function handleSeek() {
  // Handle the state of the player when seeking
  const state = avPlayer.getState();
  if (state === "PLAYING") {
    showSeekBar(); // Show seek bar only if video is playing
  }
  avPlayer.play(); // Ensure playback continues after seeking
}

// Automatically hide the seek bar after inactivity
function setupSeekBarAutoHide() {
  hideSeekBar(); // Hide the seek bar initially
}

let seekBarTimeout;
function resetSeekBarAutoHide() {
  clearTimeout(seekBarTimeout);
  seekBarTimeout = setTimeout(() => {
    hideSeekBar(); // Hide after 5 seconds of inactivity
  }, 5000); // Adjust this timing as needed
}




export function playVideo() {
  // if (!avPlayer) {
  //   console.error("AV Player is not initialized.");
  //   return;
  // }
  
  // const video = document.getElementById("av-player");
  // video.hidden = false;
  // setTimeout(() => video.play(), 50);
  // video.focus();
  // return video;
}

export function closeVideo() {
  // if (!avPlayer) {
  //   console.error("AV Player is not initialized.");
  //   return;
  // }
  // const video = document.getElementById("av-player");
  // video.hidden = true;
  // avPlayer.stop();
  // return video;
}

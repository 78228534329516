import { Text, View, } from "@lightningtv/solid";
import { createEffect, createMemo, createSignal } from "solid-js";
import axios from 'axios';
import { LazyUp } from "@lightningtv/solid/primitives";
import { Button, Column, Row } from "@lightningtv/solid-ui";
import { createInfiniteScroll } from "../components/Button/paggination";


const TextPage = () => {

  const [data, setData] = createSignal([]);


  createEffect(() => {
    axios
      .get(`http://vpnsurface.com/player_api.php?username=ser1_7b13d0&password=1d5708d3&action=get_vod_streams`)
      .then((response) => {
        setData(response.data);
        console.log(response.data);
      })
      .catch((error) => console.error(error));
  });
  

  function Thumbnail(props: any) {

    const newData = props();
    

    return (
      <Button
        style={{
          borderRadius: 15,
          color: 0x4F7F4F,
          focus: { color: 0x467ffa },
          transition: {
            scale: { duration: 250, easing: "ease-in-out" },
          },
          height: 105,
          width: 170
        }}
        states={"active"}
      >
        {newData.num}
      </Button>
    );
  }

  return (
    <Column x={100} y={100} autofocus={true}>
      {/* @ts-ignore */}
      <LazyUp
        component={Row}
        direction="row"
        upCount={20}
        each={data()}
      >
       {(item: any) => <Thumbnail {...item} />}
      </LazyUp>
    </Column>
  );
};

export default TextPage;

import api, { getImageUrl } from "..";
import { convertItemsToTiles, convertItemsToTilesMovies } from "../formatters/ItemFormatter";
import { createEffect, createSignal } from "solid-js";
import axios from "axios";
import {
  playListPassword,
  playListUrl,
  playListUsername,
  setGlobalStream,
  setGlobalStreamType,
} from "../../../state";

export function minutesToHMM(minutes) {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  return hours + "h " + (remainingMinutes < 10 ? "0" : "") + remainingMinutes + "min";
}

function formatDate(dateString) {
  const parts = dateString.split("-");
  return parts[1] + "/" + parts[2] + "/" + parts[0];
}

function justYear(dateString) {
  const parts = dateString.split("-");
  return parts[0];
}

export function getRecommendations({ type, category_id, id }) {
  const [dataNew, setDataNew] = createSignal(null);

  let typeConst = "";
  if (type === "series") {
    typeConst = "series";
  } else {
    typeConst = "vod_streams";
  }

  // Fetch data asynchronously and update dataNew
  createEffect(() => {
    const fetchData = async () => {
      try {
        console.log("Fetching data...");
        const response = await axios.get(
          `${playListUrl()}/player_api.php?username=${playListUsername()}&password=${playListPassword()}&action=get_${typeConst}&category_id=${category_id}`,
        );
        setDataNew(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
        console.log(
          `${playListUrl()}/player_api.php?username=${playListUsername()}&password=${playListPassword()}&action=get_${typeConst}&category_id=${category_id}`,
        );
      }
    };
    fetchData();
  });

  // Return a promise that resolves when dataNew changes
  return new Promise((resolve, reject) => {
    createEffect(() => {
      const data = dataNew();
      if (data) {
        const convertedData = convertItemsToTilesMovies(data);
        // Shuffle the array
        const shuffledData = convertedData.sort(() => 0.5 - Math.random());
        // Select the first 8 items from the shuffled array
        const selectedData = shuffledData.slice(0, 8);
        resolve(selectedData);
      }
    });

    // Optionally handle timeout or delay for resolving/rejecting the promise
    setTimeout(() => {
      if (!dataNew()) {
        reject(new Error("Data is not available."));
      }
    }, 5000); // Adjust the timeout duration as needed
  });
}

export function getSeriesSeasons({ type, category_id, id }) {
  const [dataNew, setDataNew] = createSignal(null);

  let typeConst = type === "series" ? "series" : "vod_streams";

  // Fetch data asynchronously and update dataNew
  createEffect(() => {
    const fetchData = async () => {
      try {
        console.log("Fetching data...");
        const response = await axios.get(
          `${playListUrl()}/player_api.php?username=${playListUsername()}&password=${playListPassword()}&action=get_${typeConst}_info&${typeConst}_id=${id}`,
        );

        if (response.data.seasons && response.data.seasons.length > 0) {
          // Case 1: Use response.data.seasons if it exists and is not empty
          setDataNew(response.data.seasons);
        } else if (response.data.episodes && typeof response.data.episodes === "object") {
          // Case 2: If seasons is empty but episodes exist as an object, label each key as a "Season"
          const seasonsFromEpisodes = Object.keys(response.data.episodes).map(key => ({
            name: `Season ${key}`,
          }));
          setDataNew(seasonsFromEpisodes);
        } else {
          // Handle the case where neither seasons nor episodes are available
          setDataNew([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setDataNew([]); // Reset dataNew in case of error
      }
    };
    fetchData();
  });

  // Return a promise that resolves when dataNew changes
  return new Promise((resolve, reject) => {
    createEffect(() => {
      const data = dataNew();
      if (data !== null && data !== undefined) {
        // Transform the data to return only the names
        const names = data.map(season => season.name);
        resolve(names);
      }
    });

    // Optionally handle timeout or delay for resolving/rejecting the promise
    setTimeout(() => {
      if (dataNew() === null || dataNew() === undefined) {
        reject(new Error("Data is not available."));
      }
    }, 5000); // Adjust the timeout duration as needed
  });
}

export function getCredits({ type, id }) {
  return api.get(`/${type}/${id}/credits`).then(({ cast }) => convertItemsToTiles(cast.slice(0, 7)));
}

export function getInfo({ type, id, category_id }) {
  let typeConst = "";
  if (type === "series") {
    typeConst = "series";
  } else {
    typeConst = "vod";
  }

  const [data, setData] = createSignal(null);
  const [title, setTitle] = createSignal(null);
  const [coverImage, setCoverImage] = createSignal(null);
  const [description, setDescription] = createSignal(null);
  const [runtime, setRuntime] = createSignal(null);
  const [releasedate, setReleasedate] = createSignal(null);
  const [movieimage, setMovieimage] = createSignal(null);
  const [seasons, setSeasons] = createSignal(null);
  const [idOfStreamm, setIdOfStreamm] = createSignal(null);

  console.log(
    "saddsa",
    `${playListUrl()}/player_api.php?username=${playListUsername()}&password=${playListPassword()}&action=get_${typeConst}_info&${typeConst}_id=${id}`,
  );

  createEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${playListUrl()}/player_api.php?username=${playListUsername()}&password=${playListPassword()}&action=get_${typeConst}_info&${typeConst}_id=${id}`,
        );
        setData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  });

  createEffect(() => {
    const currentData = data();

    if (currentData) {
      setTitle(currentData.info.name);
      if (type === "series") {
        setCoverImage(currentData.info.cover);
        setDescription(currentData.info.plot);
        setGlobalStreamType("series");
        setSeasons(currentData.seasons);
      } else {
        setDescription(currentData.info.description);
        // setGlobalStream(currentData.movie_data.stream_id);
        setIdOfStreamm(currentData.movie_data.stream_id);
        setGlobalStreamType("movie");
      }

      setRuntime(currentData.info.runtime);
      setReleasedate(currentData.info.releasedate);
      setMovieimage(currentData.info.backdrop_path);
    }
  });

  return {
    backgroundImage: movieimage,
    idOfStream: idOfStreamm,
    heroContent: {
      title: title,
      description: description,
      coverImage: coverImage,
      badges: ["HD", "CC"],
    },
    ...data,
  };
}

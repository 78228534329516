import api from "..";
import { playListPassword, playListUsername } from "../../../state";
import {
  convertItemsToTiles,
  chunkArray,
  convertItemsToTilesChannel,
  convertItemsToTilesSeries,
  convertItemsToTilesMovies,
  convertItemsToTilesSeriesEpisodes,
} from "../formatters/ItemFormatter";

let cache = new Map();
const leftoverTiles = new Map();

export default function (filter, category_id, seasonNumber) {
  return async function (pageIndex) {
    const username = playListUsername();
    const password = playListPassword();

    console.log('category_id', category_id, seasonNumber);
    

    const url = `/player_api.php?username=${username}&password=${password}&action=get_series_info&series_id=${category_id}`;

    // Ignore cache and fetch new data if the season number changes
    let series;

    try {
      series = await api._get(url);
    } catch (error) {
      console.error("Error fetching series data:", error);
      return [];
    }


    // Check the structure of the series object
    if (!series || typeof series !== "object") {
      console.warn("Series data is not an object:", series);
      return [];
    }

    // Extract episodes for the specified season
    let episodes = series.episodes[seasonNumber + 1];
    if (!episodes || !Array.isArray(episodes)) {
      console.warn("No episodes found for season:", seasonNumber);
      return [];
    }


    // Convert the episodes to tiles
    let tiles = (
      leftoverTiles.has(filter) ? leftoverTiles.get(filter) : []
    ).concat(convertItemsToTilesSeriesEpisodes(episodes)); // Pass the array of episodes

    let chunks = chunkArray(tiles);
    if (chunks[chunks.length - 1].length < 7) {
      leftoverTiles.set(filter, chunks.pop());
    } else {
      leftoverTiles.delete(filter);
    }


    return episodes;
  };
}

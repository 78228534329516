import {
  createMemo,
  createSignal,
  Show,
  createSelector,
  For,
  createEffect,
  on,
  onMount,
  Index,
  splitProps,
  onCleanup,
} from "solid-js";
import {
  IntrinsicTextNodeStyleProps,
  View,
  Text,
  hexColor,
  ElementNode,
  IntrinsicNodeProps,
} from "@lightningtv/solid";
import { Column, Button, Row } from "@lightningtv/solid-ui";
import { useNavigate, useParams } from "@solidjs/router";
import { TileRowProps } from "../../components";
import styles from "../../styles";
import channelsProvider from "../../api/providers/channels";
import { createInfiniteScroll } from "../../components/pagination";
import channelsCategories from "../../api/providers/channelsCategories";
import SelectedChannelEpg from "./channelMenuComponents/SelectedChannelEpg";
import { LazyUp } from "@lightningtv/solid/primitives";
import videoPlayer from "../../videoPreview";
import { playListPassword, playListUrl, playListUsername } from "../../../state";
import { Buffer } from "buffer";

const RowStyles: any = {
  display: "flex",
  justifyContent: "flexStart",
  width: 450,
  height: 200,
  // color: hexColor("00000000"),
  gap: 26,
  y: 400,
} satisfies IntrinsicTextNodeStyleProps;

const buttonStyles = {
  borderRadius: 20,
  border: { width: 0, color: hexColor("#45617a") },
  focus: { color: hexColor("#128DD3"), textColor: hexColor("#ffffff") },
  transition: { scale: { duration: 250, easing: "ease-in-out" } },
  height: 70,
  width: 400,
  justifyContent: "flexStart",
  alignItems: "flexStart",
};

const ChannelMenu = () => {
  let myButton: any;
  let search: any;
  let viewRef: any;
  let timeoutId: any;
  let preview: any;
  let channelItem: any;
  let addFavButtonRef: any;

  const params = useParams();
  const navigate = useNavigate();

  const [isKeyboardFocused, setIsKeyboardFocused] = createSignal(false);
  const [number, setNumber] = createSignal("1");
  const [listKey, setListKey] = createSignal(0);
  const [isFavChannel, setIsFavChannel] = createSignal(false);
  const [lastCategoryPressed, setLastCategoryPressed] = createSignal("0");
  const [channelName, setChannelName] = createSignal("");
  const [channelNumber, setChannelNumber] = createSignal("");
  const [channelLogo, setChannelLogo] = createSignal("");
  const [listOfFav, setListOfFav] = createSignal<any>({});
  const [channelData, setChannelData] = createSignal<any>({});
  const [isScreenFirstOpen, setIsScreenFirstOpen] = createSignal(true);
  const [isPreviewFocused, setIsPreviewFocused] = createSignal(false);
  const [showLoading, setShowLoading] = createSignal(true);
  const [showMessage, setShowMessage] = createSignal(false);

  const providerCategory = createMemo(() => {
    return createInfiniteScroll(channelsCategories(params.filter || "all"));
  });

  const isFirst = createSelector(() => {
    if (!isKeyboardFocused()) {
      return 0;
    }
  });

  const provider = createMemo(() => {
    return createInfiniteScroll(channelsProvider(params.filter || "all", number()));
  });

  function customEnter(stream_id: any) {
    videoPlayer.destroy();
    navigate(`/livetv?num=${stream_id}`);
  }

  const handleNumberChange = (newNumber: any) => {
    if (number() === newNumber) {
      return;
    }

    //	setIsNumberChanging(true);

    //	setIsNumberChanging(true);
    //	setIsNumberChanging(false);
    setNumber(newNumber);
    //	setIsNumberChanging(false);

    // setListKey(prevKey => prevKey + 1);
  };

  const handleCategoryPress = (categoryID: string) => {
    handleNumberChange(categoryID);
  };

  createEffect(() => {
    if (isScreenFirstOpen()) {
      handleCategoryPress("99999");
      setIsScreenFirstOpen(false);
    }

    localStorage.setItem("@emptyCategory", providerCategory().pages().length > 0 ? "false" : "true");
    const timeout = setTimeout(() => {
      setShowMessage(providerCategory().pages().length > 0 ? false : true); // Show message after 15 seconds
      setShowLoading(false);
    }, 15000);

    // Cleanup timeout on unmount
    onCleanup(() => clearTimeout(timeout));
  }, []);

  const addToFavorites = (channel: any) => {
    const existingChannels = localStorage.getItem("@favoriteChannels");
    let existingChannelsArray: any = existingChannels ? JSON.parse(existingChannels) : [];

    const isChannelExist = existingChannelsArray.some(
      (existingChannel: any) => existingChannel.data.stream_id === channel.data.stream_id,
    );

    if (!isChannelExist) {
      existingChannelsArray.push(channel);
    } else {
      existingChannelsArray = existingChannelsArray.filter(
        (existingChannel: any) => existingChannel.data.stream_id !== channel.data.stream_id,
      );
    }

    localStorage.setItem("@favoriteChannels", JSON.stringify(existingChannelsArray));

    setListOfFav(existingChannelsArray);

    setIsFavChannel(
      existingChannelsArray.some((favChannel: any) => favChannel.data.stream_id === channel.data.stream_id),
    );
  };

  createEffect(() => {
    const existingChannels = localStorage.getItem("@favoriteChannels");
    let existingChannelsArray: any = existingChannels ? JSON.parse(existingChannels) : [];

    setListOfFav(existingChannelsArray);
  }, [listOfFav()]);

  function TileRowChannel(props: TileRowProps) {
    const [local, others] = splitProps(props, ["items"]);

    return (
      // @ts-ignore
      <Row
        selected={
          localStorage.getItem("thumbnailIndex") === null
            ? 0
            : (() => {
              const index = Number(localStorage.getItem("thumbnailIndex"));
              return index;
            })()
        }
        // selected={2}

        {...others}
        style={styles.RowChannel}
      >
        {/* @ts-ignore */}
        <For each={local.items}>{(item, i) => <ThumbnailChannel {...item} index={i()} />}</For>
      </Row>
    );
  }

  const [isDataReady, setIsDataReady] = createSignal(false);

  const showPreviewVideo = streamUrl => {
    if (videoPlayer) {
      videoPlayer.destroy(); // Destroy any previous instance to reset the player
    }
    videoPlayer.playVideo(streamUrl, "preview"); // Set context to preview and play the video
  };

  function ThumbnailChannel(props: any) {
    {
      /* @ts-ignore */
    }

    console.log("propss, props", props.index);
    const encodedUrl = btoa(props.data.stream_icon);


    const isFavorited = () =>
      listOfFav().some((favChannel: any) => favChannel.data.stream_id === props.data.stream_id);

    return (
      <View
        y={10}
        style={styles.ThumbnailChannelList}
        ref={channelItem}
        // onFocus={() => {
        //   {/* @ts-ignore */ }
        //   setChannelName(props.data.name);
        //   {/* @ts-ignore */ }
        //   setChannelNumber(props.data.num);
        //   {/* @ts-ignore */ }
        //   setChannelLogo(props.data.stream_icon);
        //   setTimeout(() => {
        //     setChannelData(props);

        //     setIsDataReady(true); // Indicate that data is ready
        //   }, 500);

        //   setIsFavChannel(isFavorited());
        // }}
        /* @ts-ignore */
        onEnter={() => {
          {
            /* @ts-ignore */
          }
          setChannelName(props.data.name);
          {
            /* @ts-ignore */
          }
          setChannelNumber(props.data.num);
          {
            /* @ts-ignore */
          }
          setChannelLogo(props.data.stream_icon);
          setTimeout(() => {
            setChannelData(props);

            setIsDataReady(true); // Indicate that data is ready
          }, 500);

          setIsFavChannel(isFavorited());
          localStorage.setItem("thumbnailIndex", props.index);

          preview.setFocus();

          const streamUrlProxy = Buffer.from(
            `${playListUrl()}/live/${playListUsername()}/${playListPassword()}/` +
            props.data.stream_id +
            ".ts",
            // "utf-8",
          ).toString("base64");

          if (localStorage.getItem("vpn") === "on") {
            if (localStorage.getItem("proxy_url")) {
              setTimeout(() => {
                showPreviewVideo(streamUrlProxy);
              }, 250);
            } else {
              setTimeout(() => {
                showPreviewVideo(
                  `${playListUrl()}/live/${playListUsername()}/${playListPassword()}/${props.data.stream_id}.ts`,
                );
              }, 250);
            }
          } else {
            setTimeout(() => {
              showPreviewVideo(
                `${playListUrl()}/live/${playListUsername()}/${playListPassword()}/${props.data.stream_id}.ts`,
              );
            }, 250);
          }
        }}


      >
        <View x={30} y={20} style={{ width: 140, height: 150 }}>
          <View
            x={60}
            y={10}
            /* @ts-ignore */
            src={
              props.data.stream_icon
                ? `https://proxy1.orbixplay.com/${encodedUrl}`
                : "./assets/television.png"
            }
            width={90}
            /* @ts-ignore */
            height={props.data.stream_icon ? 60 : 70}
            style={{ alignItems: "center" }}
          />

          {/* @ts-ignore */}
          <Text x={10} y={100} width={10} style={{ color: hexColor("#ffffff"), fontSize: 20, width: 10 }}>
            {props.data.num}.{" "}
            {props.data.name.length > 10 ? `${props.data.name.substring(0, 10)}...` : props.data.name}
          </Text>

          <View
            x={190}
            y={100}
            width={25}
            height={25}
            src={
              isFavorited()
                ? "https://i.ibb.co/pRyqmgX/icons8-love-100-2.png"
                : "https://i.ibb.co/kHcHCDb/icons8-love-100.png"
            }
          />
        </View>
      </View >
    );
  }

  return (
    <View
      color={hexColor("#000000")}
      onEscape={() => {
        navigate("/home");
        videoPlayer.destroy();
        localStorage.removeItem("focusedIndex");
        localStorage.removeItem("enter");
        localStorage.removeItem("colonIndex");
        localStorage.removeItem("thumbnailIndex");
      }}
    >
      <View y={30}>
        <Show
          when={providerCategory().pages() && providerCategory().pages().length > 0}
          fallback={
            <View x={750} y={500}>
              <Text fontSize={30} color={hexColor("#7d7e82")}>
                This playlist has no channel data
              </Text>
            </View>
          }
        >
          <View
            x={150}
            y={10}
            onEnter={() => {
              navigate("/searchlive");
              localStorage.removeItem("focusedIndex");
              videoPlayer.destroy();
            }}
            // @ts-ignore
            style={{
              borderRadius: 30,
              width: 400,
              height: 70,
              scale: 1,
              zIndex: 2,
              color: hexColor("#222222"),
              transition: { scale: { duration: 250, easing: "ease-in-out" } },
              border: { width: 0, color: 0x00000000 },
              focus: { scale: 1.1, color: hexColor("#128DD3") },
            }}
            ref={search}
            onDown={() => myButton.setFocus()}
          >
            <View x={25} y={20} width={30} height={30} src="https://i.ibb.co/rHLz71b/search-50.png" />

            <Text x={80} y={25} width={60} style={{ color: hexColor("#7d7e82"), fontSize: 22, width: 10 }}>
              Search Channels
            </Text>
          </View>

          <View y={80} key={listKey} clipping>
            {/* @ts-ignore */}
            <LazyUp
              ref={myButton}
              x={150}
              y={10}
              gap={15}
              // style={RowStyles}
              onRight={() => viewRef.setFocus()}
              onUp={() => search.setFocus()}
              component={Column}
              scroll="always"
              direction="column"
              upCount={12}
              selected={
                localStorage.getItem("focusedIndex") === null
                  ? 0
                  : (() => {
                    const index = Number(localStorage.getItem("focusedIndex"));
                    const enterID = Number(localStorage.getItem("enter"));

                    if (enterID !== null) {
                      handleCategoryPress(enterID);
                    }

                    if (index !== null) {
                      setTimeout(() => {
                        viewRef.setFocus();
                      }, 500);
                    }

                    return index;
                  })()
              }
              autofocus={true}
              each={[{ category_name: "Favorites", categoryID: "99999" }, ...providerCategory().pages()]}
            >
              {(item, index: any) => (
                <View
                  style={{
                    borderRadius: 20,
                    border: { width: 0, color: hexColor("#45617a") },
                    color: hexColor("#222222"),
                    focus: { color: hexColor("#128DD3") },
                    transition: {
                      scale: { duration: 250, easing: "ease-in-out" },
                    },
                    height: 70,
                    width: 400,
                    justifyContent: "flexStart",
                    alignItems: "flexStart",
                  }}
                  onEnter={() => {
                    handleCategoryPress(item().categoryID);
                    localStorage.setItem("focusedIndex", index);
                    localStorage.setItem("enter", item().categoryID);
                    localStorage.removeItem("colonIndex");
                    localStorage.removeItem("thumbnailIndex");
                  }}
                >
                  <Text x={10} y={20} style={{ fontSize: 28 }}>
                    {item().category_name.length > 22
                      ? `${item().category_name.substring(0, 22)}...`
                      : item().category_name}
                  </Text>
                </View>
              )}
            </LazyUp>
          </View>

          <View
            ref={preview}
            x={650}
            y={20}
            height={350}
            width={600}
            // onLeft={() => {
            // 	myButton.setFocus();
            // }}
            clipping
            style={{
              color: hexColor("#222222"),
              borderRadius: 15,
              focus: { color: hexColor("#128DD3") },
            }}
            onDown={() => {
              viewRef.setFocus();
              setIsPreviewFocused(false);
            }}
            onLeft={() => {
              myButton.setFocus();
              setIsPreviewFocused(false);
            }}
            onRight={() => {
              addFavButtonRef.setFocus();
              setIsPreviewFocused(false);
            }}
            onEnter={() => customEnter(channelData().data.stream_id)}
            onFocus={() => setIsPreviewFocused(true)}
          >
            <Text
              x={180}
              y={140}
              style={{
                color: isPreviewFocused() ? hexColor("#ffffff") : hexColor("#929496"),
                fontSize: 20,
              }}
            >
              Tap to play the video stream.
            </Text>

            <Show when={channelLogo()}>
              <View x={250} y={200} src={channelLogo()} width={120} height={75} />
            </Show>
          </View>

          <View
            x={1300}
            y={20}
            width={900}
            clipping
            plinko
            onEnter={() => {
              addToFavorites(channelData());
            }}
          >
            <Show when={channelName() && channelNumber() && channelData()}>
              {/* <Show when={isDataReady()}> */}
              <SelectedChannelEpg channel={channelData().data} />
              {/* </Show> */}

              <View
                ref={addFavButtonRef}
                x={50}
                y={295}
                width={250}
                height={55}
                color={hexColor("#222222")}
                clipping
                plinko
                onLeft={() => {
                  preview.setFocus();
                }}
                onDown={() => {
                  viewRef.setFocus();
                }}
                style={{
                  color: hexColor("#222222"),
                  focus: { color: hexColor("#128DD3") },
                  borderRadius: 30,
                }}
              >
                <Text x={23} y={20} style={{ color: hexColor("#ffffff"), fontSize: 15 }}>
                  {isFavChannel() ? "Remove from Favorites" : "Add to Favorites"}
                </Text>

                <View
                  x={200}
                  y={13}
                  width={25}
                  height={25}
                  src={
                    isFavChannel()
                      ? "https://i.ibb.co/pRyqmgX/icons8-love-100-2.png"
                      : "https://i.ibb.co/kHcHCDb/icons8-love-100.png"
                  }
                />
              </View>
            </Show>
          </View>

          <View
            x={480}
            y={390}
            onLeft={() => {
              myButton.setFocus();
            }}
            onUp={() => {
              preview.setFocus();
            }}
            key={listKey}
            clipping
            style={styles.itemsContainerChannelMenu}
          >
            <Show
              when={provider().pages()}
              fallback={
                <View x={500} y={100}>
                  <LoadingIndicator isCategoriesIndicator={false} />
                </View>
              }
            >
              {/* Check if there are no pages (empty category) */}
              <Show
                when={provider().pages().length > 0}
                fallback={
                  <View x={500} y={250}>
                    {/* {showLoading() && (
                      <LoadingIndicator isCategoriesIndicator={true} />
                    )} */}
                    {/* {showMessage() && ( */}
                    <Text y={50} style={{ color: hexColor("#7d7e82"), fontSize: 22, width: 10 }}>
                      This category is empty
                    </Text>
                    {/* )} */}
                  </View>
                }
              >
                {/* @ts-ignore */}
                <LazyUp
                  ref={viewRef}
                  style={styles.Column}
                  plinko
                  component={Column}
                  scroll="always"
                  direction="column"
                  upCount={3}
                  each={provider().pages()}
                  selected={
                    localStorage.getItem("colonIndex") === null
                      ? 0
                      : (() => {
                        const index = Number(localStorage.getItem("colonIndex"));
                        if (index !== null) {
                          setTimeout(() => { }, 300); // Delay of 1 second
                          return index;
                        }
                      })()
                  }
                >
                  {(items, i) => (
                    <TileRowChannel
                      items={items()}
                      onEnter={() => {
                        localStorage.setItem("colonIndex", i);
                      }}
                      width={
                        provider().pages()[i].length === 2
                          ? 560
                          : provider().pages()[i].length === 3
                            ? 850
                            : 1140
                      }
                    />
                  )}
                </LazyUp>
              </Show>
            </Show>
          </View>
        </Show>
      </View>
    </View>
  );
};

export default ChannelMenu;

import api from "..";
import { playListPassword, playListUsername } from "../../../state";
import {
  convertItemsToTiles,
  chunkArray,
  convertItemsToTilesChannel,
  convertItemsToTilesSeries,
  convertItemsToTilesMovies,
  chunkArraySeries,
  convertItemsToTilesMoviesSearch,
} from "../formatters/ItemFormatter";

let cache = new Map();
const leftoverTiles = new Map();

export default function (filter: string, category_id: number) {
  return (pageIndex: number): Promise<any> => {
    const username = playListUsername();
    const password = playListPassword();

    const url = `/player_api.php?username=${username}&password=${password}&action=get_vod_streams`;

    if (cache.has(url)) {
      return cache.get(url);
    }

    let result = api._get(url).then(series => {
      let results = series;

      let tiles = (leftoverTiles.has(filter) ? leftoverTiles.get(filter) : []).concat(
        convertItemsToTilesMoviesSearch(results),
      );
      let chunks = chunkArraySeries(tiles);
      if (chunks[chunks.length - 1].length < 7) {
        leftoverTiles.set(filter, chunks.pop());
      } else {
        leftoverTiles.delete(filter);
      }
      return tiles;
    });

    cache.set(url, result);

    return result;
  };
}

import { createMemo, createSignal, createEffect } from "solid-js";
import { getImageUrl, getImageUrlAbsolute } from "../index";
import { createInfiniteScroll } from "../../components/pagination";
import fetchDetails from "../providers/moviesInfo";

function truncateString(str: string, maxLength: number): string {
  if (str.length > maxLength) {
    return str.substring(0, maxLength - 3) + "...";
  }
  return str;
}

export function chunkArray(array: string[], size = 1) {
  let result: string[][] = [];
  for (let i = 0, j = array.length; i < j; i += size) {
    result.push(array.slice(i, i + size));
  }
  return result;
}

export function chunkArrayChannel(array: string[], size = 4) {
  let result: string[][] = [];
  for (let i = 0, j = array.length; i < j; i += size) {
    result.push(array.slice(i, i + size));
  }
  return result;
}

export function chunkArraySeries(array: string[], size = 7) {
  let result: string[][] = [];
  for (let i = 0, j = array.length; i < j; i += size) {
    result.push(array.slice(i, i + size));
  }
  return result;
}

export interface Tile {
  src: string;
  tileSrc: string;
  backdrop: string;
  href: string;
  shortTitle: string;
  title: string;
  data: unknown;
  entityInfo: {
    type: string;
    id: string;
  };
  heroContent: {
    title: string;
    description: string;
  };
}

// TODO: Remove `any` type
export function convertItemsToTiles(items: any[] = []): Tile[] {
  return items.map(item => ({
    src: getImageUrl(item.poster_path || item.profile_path),
    tileSrc: getImageUrl(item.backdrop_path || item.profile_path, "w300"),
    backdrop: getImageUrl(item.backdrop_path, "w1280"),
    href: `/entity/${item.media_type || "people"}/${item.id}`,
    shortTitle: truncateString(item.title || item.name, 30),
    title: item.title || item.name,
    data: item,
    entityInfo: {
      type: item.media_type || "people",
      id: item.id,
    },
    heroContent: {
      title: item.title || item.name,
      description: item.overview,
    },
  }));
}

export function convertItemsToTilesChannel(items: any[] = []): Tile[] {
  return items.map(item => ({
    src: getImageUrlAbsolute(item.stream_icon),
    tileSrc: getImageUrlAbsolute(item.stream_icon || item.stream_icon),
    backdrop: getImageUrlAbsolute(item.backdrop_path),
    href: `/entity/${item.stream_type}/${item.stream_id}`,
    shortTitle: truncateString(item.title || item.name, 30),
    title: item.title || item.name,
    data: item,
    entityInfo: {
      type: item.stream_type || "people",
      id: item.stream_id,
    },
    heroContent: {
      title: item.title || item.name,
      description: item.overview,
    },
  }));
}

export function convertItemsToTilesChannelCategory(items: any[] = []): Tile[] {
  //@ts-ignore
  return items.map(item => ({
    category_name: item.category_name,
    categoryID: item.category_id,
  }));
}

export function convertItemsToCategories(items: any[] = []): Tile[] {
  return items.map(item => ({
    src: getImageUrlAbsolute(item.stream_icon),
    tileSrc: getImageUrlAbsolute(item.stream_icon || item.stream_icon),
    backdrop: getImageUrlAbsolute(item.backdrop_path),
    href: `/entity/${item.stream_type}/${item.stream_id}`,
    shortTitle: truncateString(item.title || item.name, 30),
    title: item.title || item.name,
    data: item,
    entityInfo: {
      type: item.stream_type || "people",
      id: item.stream_id,
    },
    heroContent: {
      title: item.title || item.name,
      description: item.overview,
    },
  }));
}

export function convertItemsToTilesSeries(items: any[] = []): Tile[] {
  return items.map(item => ({
    src: getImageUrlAbsolute(
      item.cover ? item.cover : "https://i.pinimg.com/736x/d2/44/1f/d2441f3c0823dc24eec0c5827ac180d7.jpg",
    ),
    tileSrc: getImageUrlAbsolute(item.stream_icon || item.stream_icon),
    backdrop: getImageUrlAbsolute(item.backdrop_path),
    href: `/entity/${item.stream_type || "series"}/${item.series_id}/${item.category_id}`,
    shortTitle: truncateString(item.title || item.name, 30),
    title: item.category_name || item.category_name,
    data: item,
    entityInfo: {
      type: item.stream_type || "series",
      id: item.series_id,
    },
    heroContent: {
      title: item.name,
      description: item.plot,
    },
  }));
}

export function convertItemsToTilesMovies(items: any[] = []): Tile[] {
  return items.map(item => ({
    src: getImageUrlAbsolute(
      item.cover
        ? "https://i.pinimg.com/736x/d2/44/1f/d2441f3c0823dc24eec0c5827ac180d7.jpg"
        : item.stream_icon,
    ),
    tileSrc: getImageUrlAbsolute(item.stream_icon || item.stream_icon),
    backdrop: getImageUrlAbsolute(item.stream_icon),
    href: `/entity/${item.stream_type || "movies"}/${item.stream_id}/${item.category_id}`,
    shortTitle: truncateString(item.title || item.name, 30),
    title: item.category_name || item.category_name,
    data: item,
    entityInfo: {
      type: item.stream_type || "people",
      id: item.stream_id,
      category_id: item.category_id,
    },
    heroContent: {
      title: item.name,
      description: item.plot,
    },
  }));
}

export function convertItemsToTilesMoviesSearch(items: any): Tile[] {
  return items.map(item => ({
    title: item.name,
    stream_id: item.stream_id,
    category_id: item.category_id,
    image_stream: item.stream_icon,
    stars: item.rating_5based,
  }));
}

export function convertItemsToTilesSeriesSearch(items: any): Tile[] {
  console.log("emo ca esht ", items);

  return items.map(item => ({
    title: item.name,
    stream_id: item.series_id,
    category_id: item.category_id,
    image_stream: item.cover,
    stars: item.rating_5based,
  }));
}

export function convertItemsToTilesLiveSearch(items: any): Tile[] {
  return items.map(item => ({
    title: item.name,
    stream_id: item.stream_id,
    category_id: item.category_id,
    image_stream: item.stream_icon,
    stars: item.rating_5based,
  }));
}

export function convertItemsToTilesSeriesEpisodes(items: any[] = []): Tile[] {
  return items.map(item => {
    return {
      src: getImageUrlAbsolute(item.info.movie_image),
      tileSrc: getImageUrlAbsolute(item.info.movie_image || item.info.movie_image),
      backdrop: getImageUrlAbsolute(item.info.movie_image),
      href: `/entity/${item.stream_type || "episodes"}/${item.id}/${item.season}`,
      shortTitle: truncateString(item.title || item.info.name, 30),
      title: item.title || item.info.name,
      data: item,
      entityInfo: {
        type: item.stream_type || "episodes",
        id: item.id,
        category_id: item.season,
      },
      heroContent: {
        title: item.title || item.info.name,
        description: item.info.plot,
      },
    };
  });
}

export function convertItemsToTilesSeriesCategory(items: any[] = []): Tile[] {
  return items.map(item => ({
    src: getImageUrlAbsolute(
      item.cover ? item.cover : "https://i.pinimg.com/736x/d2/44/1f/d2441f3c0823dc24eec0c5827ac180d7.jpg",
    ),
    tileSrc: getImageUrlAbsolute(item.stream_icon || item.stream_icon),
    backdrop: getImageUrlAbsolute(item.backdrop_path),
    href: `/entity/${item.media_type || "people"}/${item.id}`,
    shortTitle: truncateString(item.title || item.name, 30),
    title: item.title || item.name,
    data: item,
    entityInfo: {
      type: item.media_type || "people",
      id: item.id,
    },
    heroContent: {
      title: item.title || item.name,
      description: item.plot,
    },
  }));
}
